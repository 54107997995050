import * as React from 'react';
import { useProject } from 'Client/utils/hooks';
import { FormContainer, InitI18n } from 'Atoms';
import { HtmlDescription } from 'Molecules';
import { Content, EmailPageContainer, Header } from './EmailPage.styles';
import { BottomPanel } from './components';
import { EmailCapture } from './components/EmailCapture';

export interface EmailPageProps {
  title: string;
  description: string;
}

export const EmailPage: React.FC<EmailPageProps> = ({ title, description }) => {
  const project = useProject();

  return (
    <EmailPageContainer>
      <InitI18n />
      <Content>
        <FormContainer>
          <Header dangerouslySetInnerHTML={{ __html: title }} />
          <HtmlDescription>{description}</HtmlDescription>
          {!project.features.doNotAskForEmail && <EmailCapture />}
        </FormContainer>
        {!project.features.doNotAskForEmail && <BottomPanel />}
      </Content>
    </EmailPageContainer>
  );
};
