import styled, { css } from 'styled-components';

export const BottomPanelContainer = styled.div`
  margin: 3rem auto 0;
  padding: 0.8rem 1.8rem;
  border-radius: 0.2rem;
  background: ${({ theme }) => theme.colors.grey[100]};
  background-color: ${({ theme }) => theme.colors.grey[100]};
  max-width: calc(${({ theme }) => theme.formContainer.width} - 3rem);

  ${({ theme }) => css`
    ${theme.breakpoints.down('xs')} {
      width: 100%;
      height: 8rem;
      justify-content: center;
      display: flex;
      flex-direction: column;
      > p {
        font-size: 1rem;
      }
    }
  `}

  @media screen and (min-height: 600px) and (max-height: 750px) {
    margin: 2rem auto 0;
  }
`;
