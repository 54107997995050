import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from 'Atoms';
import { BottomPanelContainer } from './BottomPanel.styles';

export const BottomPanel: React.FC = () => {
  const { t } = useTranslation();
  return (
    <BottomPanelContainer>
      <Typography bold fontSize={'1.125rem'}>
        {t(`Looking to add more comments or agreements?`)}
      </Typography>
      <Typography fontSize={'1.125rem'}>
        {t(
          `Further contributions can be made once we’ve confirmed your email address.`
        )}
      </Typography>
    </BottomPanelContainer>
  );
};
